import './application.scss';

// use the rest client
import { useContext, useEffect } from 'react';

import { RestClientContext } from '../Contexts/rest_client.js';

//import state
import React, { useState } from 'react';

// array of shortened weekday names
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const colors = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7'
]

// function that reounds to 0 if the number is less than 0
function roundToZero(number) {
    if (number < 0) return 0;
    return number;
}

export default function Application() {
    const { restClient } = useContext(RestClientContext);

    const [stage, setStage] = useState(0);

    // grab all the groups
    const [groups, setGroups] = useState([]);

    // grab all the events
    const [events, setEvents] = useState([]);

    const [week, setWeek] = useState(0);

    const [fade, setFade] = useState(0);
    const [fade2, setFade2] = useState(0);
    const [calendarView, setCalendarView] = useState(0);

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [description, setDescription] = useState('');

    const [error, setError] = useState('');

    // get em
    useEffect(() => {
        (async () => {
            const result = await restClient.get('/Groups/GetGroups', {}, false);
            if (result.ok) setGroups(result.data);
        })();
        (async () => {
            const result = await restClient.get('/Dates/GetAllDates', {}, false);
            if (result.ok) setEvents(result.data);
        })();
    }, [restClient]);

    useEffect(() => {
        // set the current sunday to the sunday on the week of the first event
        // get the earliest event for the current group
        const earliestEvent = events.filter(event => event.dateGroup === calendarView.id && event.startTime > Date.now()).sort((a, b) => a.startTime - b.startTime)[0];


        // convert the startTime to a date
        let startTime;
        if (earliestEvent) startTime = new Date(earliestEvent.startTime);
        // get the day and month names of the earliest event
        if (startTime) {
            // get the sunday of that week in the form of a date
            const sunday = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate() - startTime.getDay());
            // set the week to the sunday
            setWeek(sunday);
        }
    }, [calendarView, events]);





    if (stage === 0) {

        // make it fade in on load
        const style = {
            opacity: fade / 100
        }

        const style2 = {
            opacity: roundToZero(fade / 100 - .5),
        }

        const style3 = {
            borderColor: calendarView.color,
            opacity: calendarView === 0 ? 0 : 1,
        };

        const style4 = {
            opacity: calendarView === 0 ? 0 : 1,
        }

        const style5 = {
            color: calendarView === 0 ? 'white' : calendarView.color,
        }

        // fade in
        if (fade < 500) {
            setTimeout(() => {
                setFade(fade + 1);
            }, 30);
        }



        // get the earliest event for the current group
        const earliestEvent = events.filter(event => event.dateGroup === calendarView.id && event.startTime > Date.now()).sort((a, b) => a.startTime - b.startTime)[0];


        // convert the startTime to a date
        let startTime;
        if (earliestEvent) startTime = new Date(earliestEvent.startTime);
        // get the day and month names of the earliest event
        let day;
        if (startTime) {
            day = startTime.toLocaleString('default', { weekday: 'long', day: 'numeric', month: 'long' });
        }

        const nextWeek = () => {
            if (!week) return;
            const newWeek = new Date(week.getFullYear(), week.getMonth(), week.getDate() + 7);
            setWeek(newWeek);
        }

        const prevWeek = () => {
            if (!week) return;
            const newWeek = new Date(week.getFullYear(), week.getMonth(), week.getDate() - 7);
            setWeek(newWeek);
        }

        // loop through the groups and count the number that are open
        const openGroups = groups.filter(group => group.openApplications).length;

        return (
            <div className="application">
                <meta name="viewport" content="width=device-width, initial-scale=0.6">
                </meta>
                <div className="title" style={style}>
                    Select a schedule that works for you.
                </div>

                {
                    // if there are no groups, display a message
                    openGroups === 0 ?
                        <div className="no-groups" style={style2}>
                            There are no open spots available at this time due to high demand. Please try again at a later date. We're sorry and hope to see you soon!
                        </div>
                        : null
                }

                <div className="groups" style={style2}>
                    {groups.map(group => {

                        // get a color in order to display but dont make it random and loop back if there are too many groups
                        // get current index
                        const index = groups.indexOf(group);
                        // get the color
                        const color = colors[index % colors.length];


                        const style = {
                            borderColor: color
                        }

                        if (!group.openApplications) return null;
                        if (calendarView.id === group._id) style.backgroundColor = color;

                        return (
                            <div className="group" style={style} key={group.id} onClick={() => setCalendarView({ id: group._id, color: color })}>
                                {group.name}
                            </div>
                        )
                    })}
                </div>
                <div className='startDate' style={style4}>
                    <div className='buttons' style={style5} onClick={prevWeek}>Previous</div>
                    <div className='start-day'>Next meeting is on {day}</div>
                    <div className='buttons' style={style5} onClick={nextWeek}>Next</div>
                </div>
                <div className='calendar' style={style3}>
                    {
                        days.map(day => {
                            if (!week) return null;
                            // get the actual date based on which sunday this week corresponds to and the name of the day
                            const date = new Date(week.getFullYear(), week.getMonth(), week.getDate() + days.indexOf(day));

                            // get the events for the current day
                            const eventsForDay = events.filter(event => {
                                // convert the startTime to a date
                                const startTime = new Date(event.startTime);

                                // check if the days match and the event is associated with this group
                                return startTime.getDate() === date.getDate() && startTime.getMonth() === date.getMonth() && startTime.getFullYear() === date.getFullYear() && event.dateGroup === calendarView.id;
                            });

                            // get the day of the month
                            const dayOfMonth = date.getDate();

                            return (
                                <div className='weekday'>
                                    <div className='day-name'>
                                        <span className='week-day'>
                                            {day}
                                        </span>
                                        <span className='day'>
                                            {dayOfMonth}
                                        </span>
                                    </div>
                                    {
                                        eventsForDay.map(event => {
                                            const style = {
                                                borderColor: calendarView.color
                                            }

                                            // find the ratio of the time of day and time from 9 am to 9 pm
                                            const startTime = new Date(event.startTime);

                                            // get the time from 9 am to 9 pm
                                            const time = startTime.getHours() - 9;

                                            // get the ratio of the time from 9 am to 9 pm
                                            const ratio = time / 12;

                                            // get the height of the event
                                            const height = 100 * ratio;

                                            // set the margin top to the height minus the height of the element
                                            style.marginTop = `${height}%`;



                                            return <div className="calendar-event" style={style}>
                                                <div className='event-name'>
                                                    {event.dateTitle}
                                                </div>
                                                <div className='event-time'>
                                                    {new Date(event.startTime).toLocaleString('default', { hour: 'numeric', minute: 'numeric' })}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className='select-button' style={style4} onClick={() => setStage(1)}>
                    Select
                </div>

            </div>
        )
    }
    if (stage === 1) {

        // manage fade 2
        if (fade2 < 500) {
            setTimeout(() => {
                setFade2(fade2 + 1);
            }, 20);
        }

        const style = {
            opacity: fade2 / 100
        }

        const style2 = {
            opacity: roundToZero(fade2 / 100 - .5),
        }

        const submitApplication = async () => {
            if (
                !firstName ||
                !lastName ||
                !email
            ) return;


            const application = {
                firstName,
                lastName,
                email,
                groupId: calendarView.id,
                applicationReason: description
            }

            if (phoneNumber) application.phoneNumber = phoneNumber;

            // send the application to the server
            const result = await restClient.post('/Application/Submit', application);

            if (!result.ok) setError(result.data);
            else {
                if (result.data.automaticAcceptance) setStage(2);
                else setStage(3);
            }
        }

        return (
            <div className='application'>
                <div className='title' style={style}>
                    Tell us about yourself.
                </div>
                <br />
                <div className='input-area' style={style2}>
                    <div className='input-title'>First Name <span className='note'>(Preffered Name)</span></div>
                    <input type="text" className='text-input' onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className='input-area' style={style2}>
                    <div className='input-title'>Last Name</div>
                    <input type="text" className='text-input' onChange={e => setLastName(e.target.value)} />
                </div>
                <div className='input-area' style={style2}>
                    <div className='input-title'>Email <span className='note'>(Purdue)</span></div>
                    <input type="text" className='text-input' onChange={e => setEmail(e.target.value)} />
                </div>
                <div className='input-area' style={style2}>
                    <div className='input-title'>What makes you a good candidate for the club? <span className='note'>(2-3 minimum sentences expected.)</span></div>
                    <textarea className='textarea-input' onChange={e => setDescription(e.target.value)} />
                </div>
                <div className='input-area' style={style2}>
                    <div className='input-title'>Phone Number <span className='note'>(Optional)</span></div>
                    <input type="text" className='phone-number-text-input' onChange={e => setPhoneNumber(e.target.value)} />
                </div>
                <div className='error' style={style2}>{error}</div>
                <div className='submit-button' style={style2} onClick={submitApplication}>
                    Submit
                </div>
            </div>
        )
    }

    if (stage === 2) {
        return (
            <div className='application'>
                <div className='title'>
                    Congratulations!
                </div>
                <br />
                <div className='sub-title'>
                    You have been accepted! You will receive an email soon with meeting dates and more information.
                </div>
            </div>
        )
    }

    if (stage === 3) {
        return (
            <div className='application'>
                <div className='title'>
                    Congratulations!
                </div>
                <br />
                <div className='sub-title'>
                    You have been accepted! Please navigate to the <a href='https://tinytrees.net/schedule'>calendar</a> to find your next meeting.
                </div>
            </div>
        )
    }

}
